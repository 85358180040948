/* eslint-disable no-console */
import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
} from '@backstage/core-plugin-api';
import { ApiDefinitionWidget, apiDocsConfigRef, defaultDefinitionWidgets } from '@backstage/plugin-api-docs';
import { ApiEntity } from '@backstage/catalog-model';
import { RedocStandalone } from 'redoc';
import React from 'react';
import yaml from 'js-yaml';


export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  createApiFactory({
    api: apiDocsConfigRef,
    deps: {},
    factory: () => {
      const definitionWidgets = defaultDefinitionWidgets();
      return {
        getApiDefinitionWidget: (apiEntity: ApiEntity) => {
          if (apiEntity.spec.type === 'openapi') {
            return {
              type: 'openapi',
              title: 'Preview',
              component: (definition: string) => {
                const definitionJson = yaml.load(definition);
                if (!definitionJson) {
                  throw Error('Could not parse openapi yaml');
                }
                return <RedocStandalone spec={definitionJson as object}/>
              },
            } as ApiDefinitionWidget;
          }
          return definitionWidgets.find((d: any) => d.type === apiEntity.spec.type);
        }
      };
    },
  }),
  ScmAuth.createDefaultApiFactory(),
];
