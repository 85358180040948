import React from 'react';
import SpinnerAnimation from '../SpinnerAnimation/SpinnerAnimation';

interface RecastLogoSpinnerProps {
	className?: string;
}

const RecastLogoSpinner: React.FC<RecastLogoSpinnerProps> = ({ className }) => {
	return (
		<div className={`recastSpinner ${className}`}>
			<SpinnerAnimation />
		</div>
	);
};

RecastLogoSpinner.defaultProps = {
	className: ''
};

export default RecastLogoSpinner;
