import thinWoff from './fonts/RecastSans-Thin.woff2';
import thinItalicWoff from './fonts/RecastSans-ThinItalic.woff2';
import lightWoff from './fonts/RecastSans-Light.woff2';
import lightItalicWoff from './fonts/RecastSans-LightItalic.woff2';
import bookWoff from './fonts/RecastSans-Book.woff2';
import bookItalicWoff from './fonts/RecastSans-BookItalic.woff2';
import regularWoff from './fonts/RecastSans-Regular.woff2';
import italicWoff from './fonts/RecastSans-Italic.woff2';
import mediumWoff from './fonts/RecastSans-Medium.woff2';
import mediumItalicWoff from './fonts/RecastSans-MediumItalic.woff2';
import boldWoff from './fonts/RecastSans-Bold.woff2';
import boldItalicWoff from './fonts/RecastSans-BoldItalic.woff2';

const thin = {
  fontFamily: 'Recast Sans',
  fontStyle: 'normal',
  fontWeight: 100,
  src: `
    local('Recast Sans'),
    local(RecastSans-Thin),
    url(${thinWoff}) format('woff2')
  `
};

const thinItalic = {
  fontFamily: 'Recast Sans',
  fontStyle: 'italic',
  fontWeight: 100,
  src: `
    local('Recast Sans'),
    local(RecastSans-ThinItalic),
    url(${thinItalicWoff}) format('woff2')
  `
};

const light = {
  fontFamily: 'Recast Sans',
  fontStyle: 'normal',
  fontWeight: 200,
  src: `
    local('Recast Sans'),
    local(RecastSans-Light),
    url(${lightWoff}) format('woff2')
  `
};

const lightItalic = {
  fontFamily: 'Recast Sans',
  fontStyle: 'italic',
  fontWeight: 200,
  src: `
    local('Recast Sans'),
    local(RecastSans-LightItalic),
    url(${lightItalicWoff}) format('woff2')
  `
};

const book = {
  fontFamily: 'Recast Sans',
  fontStyle: 'normal',
  fontWeight: 300,
  src: `
    local('Recast Sans'),
    local(RecastSans-Book),
    url(${bookWoff}) format('woff2')
  `
};

const bookItalic = {
  fontFamily: 'Recast Sans',
  fontStyle: 'italic',
  fontWeight: 300,
  src: `
    local('Recast Sans'),
    local(RecastSans-BookItalic),
    url(${bookItalicWoff}) format('woff2')
  `
};

const regular = {
  fontFamily: 'Recast Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `
    local('Recast Sans'),
    local(RecastSans-Regular),
    url(${regularWoff}) format('woff2')
  `
};

const italic = {
  fontFamily: 'Recast Sans',
  fontStyle: 'italic',
  fontWeight: 400,
  src: `
    local('Recast Sans'),
    local(RecastSans-italic),
    url(${italicWoff}) format('woff2')
  `
};

const medium = {
  fontFamily: 'Recast Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  src: `
    local('Recast Sans'),
    local(RecastSans-Medium),
    url(${mediumWoff}) format('woff2')
  `
};

const mediumItalic = {
  fontFamily: 'Recast Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  src: `
    local('Recast Sans'),
    local(RecastSans-MediumItalic),
    url(${mediumItalicWoff}) format('woff2')
  `
};

const bold = {
  fontFamily: 'Recast Sans',
  fontStyle: 'normal',
  fontWeight: 800,
  src: `
    local('Recast Sans'),
    local(RecastSans-Bold),
    url(${boldWoff}) format('woff2')
  `
};

const boldItalic = {
  fontFamily: 'Recast Sans',
  fontStyle: 'italic',
  fontWeight: 800,
  src: `
    local('Recast Sans'),
    local(RecastSans-BoldItalic),
    url(${boldItalicWoff}) format('woff2')
  `
};

const fonts = [thin,thinItalic,light,lightItalic,book,bookItalic,regular,italic,medium,mediumItalic,bold,boldItalic];

export default fonts;
