import { createTheme, createThemeOverrides, lightTheme } from "@backstage/theme";
import { BackstageOverrides } from '@backstage/core-components';
import fonts from "./fonts";

const theme = createTheme({
  ...lightTheme,
  palette: lightTheme.palette,
  fontFamily: 'Recast Sans',
  defaultPageTheme: 'home',
});

const themeOverrides = createThemeOverrides(theme);

themeOverrides.MuiCssBaseline = {
  '@global': {
    'a': {
      color: 'inherit',
      textDecoration: 'none',
    },
    '@font-face': fonts
  }
}

export const createCustomThemeOverrides = (): BackstageOverrides => {
  return {
    BackstageHeader: {
      title: {
        color: '#1a1a1a'
      },
      subtitle: {
        color: '#1a1a1a'
      },
      header: {
        width: 'auto',
        // margin: '20px',
        boxShadow: 'none',
        backgroundImage: 'none',
        backgroundColor: '#00e8d0', 
        // borderBottom: `4px solid ${thisTheme.palette.primary.main}`,
      },
    },
  };
};

theme.overrides = {
  ...lightTheme.overrides,
  ...themeOverrides,
  ...createCustomThemeOverrides(),
};

export default theme;
