import React, { CSSProperties, MutableRefObject, useEffect, useRef } from 'react';
import lottie, { AnimationEventName, AnimationItem, AnimationSegment } from 'lottie-web';

interface LottieProps {
	animationData: any;
	autoplay?: boolean;
	loop?: boolean;
	style?: CSSProperties;
	onComplete?: () => void;
	onDOMLoaded?: () => void;
	segments?: AnimationSegment | AnimationSegment[];
}

const useAnimationEventListener = (
	animation: MutableRefObject<AnimationItem | undefined>,
	event: AnimationEventName,
	callback?: () => void
) => {
	useEffect(() => {
		if (callback) {
			animation.current?.addEventListener(event, callback);
			return () => animation.current?.removeEventListener(event, callback);
		}
		return undefined;
	}, [callback, event]);
};

export const Lottie = (props: LottieProps) => {
	const { animationData, autoplay, loop, style, onComplete, onDOMLoaded, segments } = props;

	const container = useRef<HTMLDivElement>(null);
	const animation = useRef<AnimationItem>();

	useEffect(() => {
		if (container.current) {
			animation.current = lottie.loadAnimation({ container: container.current, animationData, autoplay, loop });
		}
		return () => {
			animation.current?.destroy();
			animation.current = undefined;
		};
	}, [animationData, autoplay, loop]);

	useAnimationEventListener(animation, 'complete', onComplete);
	useAnimationEventListener(animation, 'DOMLoaded', onDOMLoaded);

	useEffect(() => {
		if (segments) {
			animation.current?.playSegments(segments);
		}
	}, [segments]);

	return <div ref={container} style={style} />;
};
