import { Entity } from "@backstage/catalog-model";
// import { useKubernetesObjects } from "@backstage/plugin-kubernetes";
import React from "react";
import { KubernetesContent } from "../kubernetes/KubernetesContent";

interface KubernetesTagColumnProps {
  entity: Entity;
}

export const KubernetesDetailsPanel= ({ entity }: KubernetesTagColumnProps) => {
  return <KubernetesContent entity={entity} refreshIntervalMs={300000} />
}
