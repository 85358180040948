import { Entity } from "@backstage/catalog-model";
import { Link } from "@backstage/core-components";
import { Typography } from "@material-ui/core";
import React from "react";
import RecastLogoSpinner from "../../components/RecastLogoSpinner/RecastLogoSpinner";
// import Spinner from "../../components/Spinner/Spinner";
import { AllClusterDeploymentDetails, DeploymentDetails } from "./CatalogTable";

interface KubernetesTagColumnProps {
  entity: Entity;
  deploymentDetails: AllClusterDeploymentDetails;
  environment: 'tst' | 'stg' | 'prod';
}


export const KubernetesTagColumn = ({ deploymentDetails, environment }: KubernetesTagColumnProps) => {
  let deployment: DeploymentDetails;
  let usingTst = false;
  if (deploymentDetails.isNotDeployed) {
    return <>No deployment found</>;
  } else if (deploymentDetails[environment]) {
    // @ts-ignore
    deployment = deploymentDetails[environment];
  } else if (deploymentDetails.tst) {
    deployment = deploymentDetails.tst;
    usingTst = true;
  } else {
    return <RecastLogoSpinner />;
  }
  return (<>
    <Typography variant='body2'>{deployment.tag}</Typography>
    <Typography variant='caption' gutterBottom={false}>
      <Link to={deployment.workflow}>{usingTst ? '[tst-blu]' : ''}{deployment.actor} <span style={{fontWeight: 800}}>|</span> {new Date(deployment.lastRestarted).toLocaleString()}</Link>
    </Typography>
    </>);
}
