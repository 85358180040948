import { createRoutableExtension, createRouteRef } from '@backstage/core-plugin-api';
import { EntityKubernetesContentProps, kubernetesPlugin } from './plugin';

export const rootCatalogKubernetesRouteRef = createRouteRef({
  id: 'kubernetes',
});

export const EntityKubernetesContent: (
  props: EntityKubernetesContentProps,
) => JSX.Element = kubernetesPlugin.provide(
  createRoutableExtension({
    name: 'EntityKubernetesContent',
    component: () => import('../../components/catalog/Router').then(m => m.Router),
    mountPoint: rootCatalogKubernetesRouteRef,
  }),
);
