export default {
	v: '5.6.5',
	fr: 60,
	ip: 0,
	op: 24,
	w: 100,
	h: 100,
	nm: 'Flower',
	ddd: 0,
	assets: [],
	layers: [
		{
			ddd: 0,
			ind: 1,
			ty: 4,
			nm: 'Shape Layer 13',
			sr: 1,
			ks: {
				o: {
					a: 1,
					k: [
						{ i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 18, s: [100] },
						{ t: 24.999609375, s: [70] }
					],
					ix: 11
				},
				r: { a: 0, k: 324, ix: 10 },
				p: { a: 0, k: [99.375, 57.75, 0], ix: 2 },
				a: { a: 0, k: [27.014, 31.134, 0], ix: 1 },
				s: { a: 0, k: [67.975, 67.975, 100], ix: 6 }
			},
			ao: 0,
			shapes: [
				{
					ty: 'gr',
					it: [
						{
							ind: 0,
							ty: 'sh',
							ix: 1,
							ks: {
								a: 0,
								k: {
									i: [
										[0, 0],
										[0, 0],
										[0, 0],
										[0, 0]
									],
									o: [
										[0, 0],
										[0, 0],
										[0, 0],
										[0, 0]
									],
									v: [
										[-49, -49.75],
										[-25.25, -1.75],
										[-3, -1.75],
										[-3, -24]
									],
									c: true
								},
								ix: 2
							},
							nm: 'Path 1',
							mn: 'ADBE Vector Shape - Group',
							hd: false
						},
						{
							ty: 'fl',
							c: { a: 0, k: [0, 0.909803926945, 0.815686285496, 1], ix: 4 },
							o: { a: 0, k: 30, ix: 5 },
							r: 1,
							bm: 0,
							nm: 'Fill 1',
							mn: 'ADBE Vector Graphic - Fill',
							hd: false
						},
						{
							ty: 'tr',
							p: { a: 0, k: [29.894, 32.654], ix: 2 },
							a: { a: 0, k: [0, 0], ix: 1 },
							s: { a: 0, k: [100, 100], ix: 3 },
							r: { a: 0, k: 0, ix: 6 },
							o: { a: 0, k: 100, ix: 7 },
							sk: { a: 0, k: 0, ix: 4 },
							sa: { a: 0, k: 0, ix: 5 },
							nm: 'Transform'
						}
					],
					nm: 'Shape 1',
					np: 3,
					cix: 2,
					bm: 0,
					ix: 1,
					mn: 'ADBE Vector Group',
					hd: false
				}
			],
			ip: 18,
			op: 25,
			st: 14.4,
			bm: 0
		},
		{
			ddd: 0,
			ind: 2,
			ty: 4,
			nm: 'Shape Layer 12',
			sr: 1,
			ks: {
				o: {
					a: 1,
					k: [
						{ i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 16, s: [30] },
						{ t: 22.999609375, s: [0] }
					],
					ix: 11
				},
				r: { a: 0, k: 288, ix: 10 },
				p: { a: 0, k: [95.625, 28.25, 0], ix: 2 },
				a: { a: 0, k: [27.014, 31.134, 0], ix: 1 },
				s: { a: 0, k: [67.975, 67.975, 100], ix: 6 }
			},
			ao: 0,
			shapes: [
				{
					ty: 'gr',
					it: [
						{
							ind: 0,
							ty: 'sh',
							ix: 1,
							ks: {
								a: 0,
								k: {
									i: [
										[0, 0],
										[0, 0],
										[0, 0],
										[0, 0]
									],
									o: [
										[0, 0],
										[0, 0],
										[0, 0],
										[0, 0]
									],
									v: [
										[-49, -49.75],
										[-25.25, -1.75],
										[-3, -1.75],
										[-3, -24]
									],
									c: true
								},
								ix: 2
							},
							nm: 'Path 1',
							mn: 'ADBE Vector Shape - Group',
							hd: false
						},
						{
							ty: 'fl',
							c: { a: 0, k: [0, 0.909803926945, 0.815686285496, 1], ix: 4 },
							o: { a: 0, k: 100, ix: 5 },
							r: 1,
							bm: 0,
							nm: 'Fill 1',
							mn: 'ADBE Vector Graphic - Fill',
							hd: false
						},
						{
							ty: 'tr',
							p: { a: 0, k: [29.894, 32.654], ix: 2 },
							a: { a: 0, k: [0, 0], ix: 1 },
							s: { a: 0, k: [100, 100], ix: 3 },
							r: { a: 0, k: 0, ix: 6 },
							o: { a: 0, k: 100, ix: 7 },
							sk: { a: 0, k: 0, ix: 4 },
							sa: { a: 0, k: 0, ix: 5 },
							nm: 'Transform'
						}
					],
					nm: 'Shape 1',
					np: 3,
					cix: 2,
					bm: 0,
					ix: 1,
					mn: 'ADBE Vector Group',
					hd: false
				}
			],
			ip: 16,
			op: 23,
			st: 12.4,
			bm: 0
		},
		{
			ddd: 0,
			ind: 3,
			ty: 4,
			nm: 'Shape Layer 11',
			sr: 1,
			ks: {
				o: {
					a: 1,
					k: [
						{ i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 14, s: [30] },
						{ t: 20.999609375, s: [0] }
					],
					ix: 11
				},
				r: { a: 0, k: 252, ix: 10 },
				p: { a: 0, k: [75.375, 6.75, 0], ix: 2 },
				a: { a: 0, k: [27.014, 31.134, 0], ix: 1 },
				s: { a: 0, k: [67.975, 67.975, 100], ix: 6 }
			},
			ao: 0,
			shapes: [
				{
					ty: 'gr',
					it: [
						{
							ind: 0,
							ty: 'sh',
							ix: 1,
							ks: {
								a: 0,
								k: {
									i: [
										[0, 0],
										[0, 0],
										[0, 0],
										[0, 0]
									],
									o: [
										[0, 0],
										[0, 0],
										[0, 0],
										[0, 0]
									],
									v: [
										[-49, -49.75],
										[-25.25, -1.75],
										[-3, -1.75],
										[-3, -24]
									],
									c: true
								},
								ix: 2
							},
							nm: 'Path 1',
							mn: 'ADBE Vector Shape - Group',
							hd: false
						},
						{
							ty: 'fl',
							c: { a: 0, k: [0, 0.909803926945, 0.815686285496, 1], ix: 4 },
							o: { a: 0, k: 100, ix: 5 },
							r: 1,
							bm: 0,
							nm: 'Fill 1',
							mn: 'ADBE Vector Graphic - Fill',
							hd: false
						},
						{
							ty: 'tr',
							p: { a: 0, k: [29.894, 32.654], ix: 2 },
							a: { a: 0, k: [0, 0], ix: 1 },
							s: { a: 0, k: [100, 100], ix: 3 },
							r: { a: 0, k: 0, ix: 6 },
							o: { a: 0, k: 100, ix: 7 },
							sk: { a: 0, k: 0, ix: 4 },
							sa: { a: 0, k: 0, ix: 5 },
							nm: 'Transform'
						}
					],
					nm: 'Shape 1',
					np: 3,
					cix: 2,
					bm: 0,
					ix: 1,
					mn: 'ADBE Vector Group',
					hd: false
				}
			],
			ip: 14,
			op: 21,
			st: 10.4,
			bm: 0
		},
		{
			ddd: 0,
			ind: 4,
			ty: 4,
			nm: 'Shape Layer 10',
			sr: 1,
			ks: {
				o: {
					a: 1,
					k: [
						{ i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 12, s: [30] },
						{ t: 18.999609375, s: [0] }
					],
					ix: 11
				},
				r: { a: 0, k: 216, ix: 10 },
				p: { a: 0, k: [46.375, 1.5, 0], ix: 2 },
				a: { a: 0, k: [27.014, 31.134, 0], ix: 1 },
				s: { a: 0, k: [67.975, 67.975, 100], ix: 6 }
			},
			ao: 0,
			shapes: [
				{
					ty: 'gr',
					it: [
						{
							ind: 0,
							ty: 'sh',
							ix: 1,
							ks: {
								a: 0,
								k: {
									i: [
										[0, 0],
										[0, 0],
										[0, 0],
										[0, 0]
									],
									o: [
										[0, 0],
										[0, 0],
										[0, 0],
										[0, 0]
									],
									v: [
										[-49, -49.75],
										[-25.25, -1.75],
										[-3, -1.75],
										[-3, -24]
									],
									c: true
								},
								ix: 2
							},
							nm: 'Path 1',
							mn: 'ADBE Vector Shape - Group',
							hd: false
						},
						{
							ty: 'fl',
							c: { a: 0, k: [0, 0.909803926945, 0.815686285496, 1], ix: 4 },
							o: { a: 0, k: 100, ix: 5 },
							r: 1,
							bm: 0,
							nm: 'Fill 1',
							mn: 'ADBE Vector Graphic - Fill',
							hd: false
						},
						{
							ty: 'tr',
							p: { a: 0, k: [29.894, 32.654], ix: 2 },
							a: { a: 0, k: [0, 0], ix: 1 },
							s: { a: 0, k: [100, 100], ix: 3 },
							r: { a: 0, k: 0, ix: 6 },
							o: { a: 0, k: 100, ix: 7 },
							sk: { a: 0, k: 0, ix: 4 },
							sa: { a: 0, k: 0, ix: 5 },
							nm: 'Transform'
						}
					],
					nm: 'Shape 1',
					np: 3,
					cix: 2,
					bm: 0,
					ix: 1,
					mn: 'ADBE Vector Group',
					hd: false
				}
			],
			ip: 12,
			op: 19,
			st: 8.4,
			bm: 0
		},
		{
			ddd: 0,
			ind: 5,
			ty: 4,
			nm: 'Shape Layer 8',
			sr: 1,
			ks: {
				o: {
					a: 1,
					k: [
						{ i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 10, s: [30] },
						{ t: 16.999609375, s: [0] }
					],
					ix: 11
				},
				r: { a: 0, k: 180, ix: 10 },
				p: { a: 0, k: [19.625, 14.25, 0], ix: 2 },
				a: { a: 0, k: [27.014, 31.134, 0], ix: 1 },
				s: { a: 0, k: [67.975, 67.975, 100], ix: 6 }
			},
			ao: 0,
			shapes: [
				{
					ty: 'gr',
					it: [
						{
							ind: 0,
							ty: 'sh',
							ix: 1,
							ks: {
								a: 0,
								k: {
									i: [
										[0, 0],
										[0, 0],
										[0, 0],
										[0, 0]
									],
									o: [
										[0, 0],
										[0, 0],
										[0, 0],
										[0, 0]
									],
									v: [
										[-49, -49.75],
										[-25.25, -1.75],
										[-3, -1.75],
										[-3, -24]
									],
									c: true
								},
								ix: 2
							},
							nm: 'Path 1',
							mn: 'ADBE Vector Shape - Group',
							hd: false
						},
						{
							ty: 'fl',
							c: { a: 0, k: [0, 0.909803926945, 0.815686285496, 1], ix: 4 },
							o: { a: 0, k: 100, ix: 5 },
							r: 1,
							bm: 0,
							nm: 'Fill 1',
							mn: 'ADBE Vector Graphic - Fill',
							hd: false
						},
						{
							ty: 'tr',
							p: { a: 0, k: [29.894, 32.654], ix: 2 },
							a: { a: 0, k: [0, 0], ix: 1 },
							s: { a: 0, k: [100, 100], ix: 3 },
							r: { a: 0, k: 0, ix: 6 },
							o: { a: 0, k: 100, ix: 7 },
							sk: { a: 0, k: 0, ix: 4 },
							sa: { a: 0, k: 0, ix: 5 },
							nm: 'Transform'
						}
					],
					nm: 'Shape 1',
					np: 3,
					cix: 2,
					bm: 0,
					ix: 1,
					mn: 'ADBE Vector Group',
					hd: false
				}
			],
			ip: 10,
			op: 17,
			st: 6.4,
			bm: 0
		},
		{
			ddd: 0,
			ind: 6,
			ty: 4,
			nm: 'Shape Layer 7',
			sr: 1,
			ks: {
				o: {
					a: 1,
					k: [
						{ i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 8, s: [30] },
						{ t: 14.999609375, s: [0] }
					],
					ix: 11
				},
				r: { a: 0, k: 144, ix: 10 },
				p: { a: 0, k: [5.125, 41, 0], ix: 2 },
				a: { a: 0, k: [27.014, 31.134, 0], ix: 1 },
				s: { a: 0, k: [67.975, 67.975, 100], ix: 6 }
			},
			ao: 0,
			shapes: [
				{
					ty: 'gr',
					it: [
						{
							ind: 0,
							ty: 'sh',
							ix: 1,
							ks: {
								a: 0,
								k: {
									i: [
										[0, 0],
										[0, 0],
										[0, 0],
										[0, 0]
									],
									o: [
										[0, 0],
										[0, 0],
										[0, 0],
										[0, 0]
									],
									v: [
										[-49, -49.75],
										[-25.25, -1.75],
										[-3, -1.75],
										[-3, -24]
									],
									c: true
								},
								ix: 2
							},
							nm: 'Path 1',
							mn: 'ADBE Vector Shape - Group',
							hd: false
						},
						{
							ty: 'fl',
							c: { a: 0, k: [0, 0.909803926945, 0.815686285496, 1], ix: 4 },
							o: { a: 0, k: 100, ix: 5 },
							r: 1,
							bm: 0,
							nm: 'Fill 1',
							mn: 'ADBE Vector Graphic - Fill',
							hd: false
						},
						{
							ty: 'tr',
							p: { a: 0, k: [29.894, 32.654], ix: 2 },
							a: { a: 0, k: [0, 0], ix: 1 },
							s: { a: 0, k: [100, 100], ix: 3 },
							r: { a: 0, k: 0, ix: 6 },
							o: { a: 0, k: 100, ix: 7 },
							sk: { a: 0, k: 0, ix: 4 },
							sa: { a: 0, k: 0, ix: 5 },
							nm: 'Transform'
						}
					],
					nm: 'Shape 1',
					np: 3,
					cix: 2,
					bm: 0,
					ix: 1,
					mn: 'ADBE Vector Group',
					hd: false
				}
			],
			ip: 8,
			op: 15,
			st: 4.4,
			bm: 0
		},
		{
			ddd: 0,
			ind: 7,
			ty: 4,
			nm: 'Shape Layer 6',
			sr: 1,
			ks: {
				o: {
					a: 1,
					k: [
						{ i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 6, s: [30] },
						{ t: 12.999609375, s: [0] }
					],
					ix: 11
				},
				r: { a: 0, k: 108, ix: 10 },
				p: { a: 0, k: [10.125, 70.25, 0], ix: 2 },
				a: { a: 0, k: [27.014, 31.134, 0], ix: 1 },
				s: { a: 0, k: [67.975, 67.975, 100], ix: 6 }
			},
			ao: 0,
			shapes: [
				{
					ty: 'gr',
					it: [
						{
							ind: 0,
							ty: 'sh',
							ix: 1,
							ks: {
								a: 0,
								k: {
									i: [
										[0, 0],
										[0, 0],
										[0, 0],
										[0, 0]
									],
									o: [
										[0, 0],
										[0, 0],
										[0, 0],
										[0, 0]
									],
									v: [
										[-49, -49.75],
										[-25.25, -1.75],
										[-3, -1.75],
										[-3, -24]
									],
									c: true
								},
								ix: 2
							},
							nm: 'Path 1',
							mn: 'ADBE Vector Shape - Group',
							hd: false
						},
						{
							ty: 'fl',
							c: { a: 0, k: [0, 0.909803926945, 0.815686285496, 1], ix: 4 },
							o: { a: 0, k: 100, ix: 5 },
							r: 1,
							bm: 0,
							nm: 'Fill 1',
							mn: 'ADBE Vector Graphic - Fill',
							hd: false
						},
						{
							ty: 'tr',
							p: { a: 0, k: [29.894, 32.654], ix: 2 },
							a: { a: 0, k: [0, 0], ix: 1 },
							s: { a: 0, k: [100, 100], ix: 3 },
							r: { a: 0, k: 0, ix: 6 },
							o: { a: 0, k: 100, ix: 7 },
							sk: { a: 0, k: 0, ix: 4 },
							sa: { a: 0, k: 0, ix: 5 },
							nm: 'Transform'
						}
					],
					nm: 'Shape 1',
					np: 3,
					cix: 2,
					bm: 0,
					ix: 1,
					mn: 'ADBE Vector Group',
					hd: false
				}
			],
			ip: 6,
			op: 13,
			st: 2.4,
			bm: 0
		},
		{
			ddd: 0,
			ind: 8,
			ty: 4,
			nm: 'Shape Layer 5',
			sr: 1,
			ks: {
				o: {
					a: 1,
					k: [
						{ i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 4, s: [30] },
						{ t: 10.999609375, s: [0] }
					],
					ix: 11
				},
				r: { a: 0, k: 72, ix: 10 },
				p: { a: 0, k: [30.375, 92.25, 0], ix: 2 },
				a: { a: 0, k: [27.014, 31.134, 0], ix: 1 },
				s: { a: 0, k: [67.975, 67.975, 100], ix: 6 }
			},
			ao: 0,
			shapes: [
				{
					ty: 'gr',
					it: [
						{
							ind: 0,
							ty: 'sh',
							ix: 1,
							ks: {
								a: 0,
								k: {
									i: [
										[0, 0],
										[0, 0],
										[0, 0],
										[0, 0]
									],
									o: [
										[0, 0],
										[0, 0],
										[0, 0],
										[0, 0]
									],
									v: [
										[-49, -49.75],
										[-25.25, -1.75],
										[-3, -1.75],
										[-3, -24]
									],
									c: true
								},
								ix: 2
							},
							nm: 'Path 1',
							mn: 'ADBE Vector Shape - Group',
							hd: false
						},
						{
							ty: 'fl',
							c: { a: 0, k: [0, 0.909803926945, 0.815686285496, 1], ix: 4 },
							o: { a: 0, k: 100, ix: 5 },
							r: 1,
							bm: 0,
							nm: 'Fill 1',
							mn: 'ADBE Vector Graphic - Fill',
							hd: false
						},
						{
							ty: 'tr',
							p: { a: 0, k: [29.894, 32.654], ix: 2 },
							a: { a: 0, k: [0, 0], ix: 1 },
							s: { a: 0, k: [100, 100], ix: 3 },
							r: { a: 0, k: 0, ix: 6 },
							o: { a: 0, k: 100, ix: 7 },
							sk: { a: 0, k: 0, ix: 4 },
							sa: { a: 0, k: 0, ix: 5 },
							nm: 'Transform'
						}
					],
					nm: 'Shape 1',
					np: 3,
					cix: 2,
					bm: 0,
					ix: 1,
					mn: 'ADBE Vector Group',
					hd: false
				}
			],
			ip: 4,
			op: 11,
			st: 0.4,
			bm: 0
		},
		{
			ddd: 0,
			ind: 9,
			ty: 4,
			nm: 'Shape Layer 4',
			sr: 1,
			ks: {
				o: {
					a: 1,
					k: [
						{ i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 2, s: [30] },
						{ t: 8.999609375, s: [0] }
					],
					ix: 11
				},
				r: { a: 0, k: 36, ix: 10 },
				p: { a: 0, k: [59.375, 98, 0], ix: 2 },
				a: { a: 0, k: [27.014, 31.134, 0], ix: 1 },
				s: { a: 0, k: [67.975, 67.975, 100], ix: 6 }
			},
			ao: 0,
			shapes: [
				{
					ty: 'gr',
					it: [
						{
							ind: 0,
							ty: 'sh',
							ix: 1,
							ks: {
								a: 0,
								k: {
									i: [
										[0, 0],
										[0, 0],
										[0, 0],
										[0, 0]
									],
									o: [
										[0, 0],
										[0, 0],
										[0, 0],
										[0, 0]
									],
									v: [
										[-49, -49.75],
										[-25.25, -1.75],
										[-3, -1.75],
										[-3, -24]
									],
									c: true
								},
								ix: 2
							},
							nm: 'Path 1',
							mn: 'ADBE Vector Shape - Group',
							hd: false
						},
						{
							ty: 'fl',
							c: { a: 0, k: [0, 0.909803926945, 0.815686285496, 1], ix: 4 },
							o: { a: 0, k: 100, ix: 5 },
							r: 1,
							bm: 0,
							nm: 'Fill 1',
							mn: 'ADBE Vector Graphic - Fill',
							hd: false
						},
						{
							ty: 'tr',
							p: { a: 0, k: [29.894, 32.654], ix: 2 },
							a: { a: 0, k: [0, 0], ix: 1 },
							s: { a: 0, k: [100, 100], ix: 3 },
							r: { a: 0, k: 0, ix: 6 },
							o: { a: 0, k: 100, ix: 7 },
							sk: { a: 0, k: 0, ix: 4 },
							sa: { a: 0, k: 0, ix: 5 },
							nm: 'Transform'
						}
					],
					nm: 'Shape 1',
					np: 3,
					cix: 2,
					bm: 0,
					ix: 1,
					mn: 'ADBE Vector Group',
					hd: false
				}
			],
			ip: 2,
			op: 9,
			st: -1.6,
			bm: 0
		},
		{
			ddd: 0,
			ind: 10,
			ty: 4,
			nm: 'Shape Layer 3',
			sr: 1,
			ks: {
				o: {
					a: 1,
					k: [
						{ i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [100] },
						{ t: 23.999609375, s: [0] }
					],
					ix: 11
				},
				r: { a: 0, k: 0, ix: 10 },
				p: { a: 0, k: [85.875, 84.5, 0], ix: 2 },
				a: { a: 0, k: [27.014, 31.134, 0], ix: 1 },
				s: { a: 0, k: [67.975, 67.975, 100], ix: 6 }
			},
			ao: 0,
			shapes: [
				{
					ty: 'gr',
					it: [
						{
							ind: 0,
							ty: 'sh',
							ix: 1,
							ks: {
								a: 0,
								k: {
									i: [
										[0, 0],
										[0, 0],
										[0, 0],
										[0, 0]
									],
									o: [
										[0, 0],
										[0, 0],
										[0, 0],
										[0, 0]
									],
									v: [
										[-49, -49.75],
										[-25.25, -1.75],
										[-3, -1.75],
										[-3, -24]
									],
									c: true
								},
								ix: 2
							},
							nm: 'Path 1',
							mn: 'ADBE Vector Shape - Group',
							hd: false
						},
						{
							ty: 'fl',
							c: { a: 0, k: [0, 0.909803926945, 0.815686285496, 1], ix: 4 },
							o: { a: 0, k: 100, ix: 5 },
							r: 1,
							bm: 0,
							nm: 'Fill 1',
							mn: 'ADBE Vector Graphic - Fill',
							hd: false
						},
						{
							ty: 'tr',
							p: { a: 0, k: [29.894, 32.654], ix: 2 },
							a: { a: 0, k: [0, 0], ix: 1 },
							s: { a: 0, k: [100, 100], ix: 3 },
							r: { a: 0, k: 0, ix: 6 },
							o: { a: 0, k: 100, ix: 7 },
							sk: { a: 0, k: 0, ix: 4 },
							sa: { a: 0, k: 0, ix: 5 },
							nm: 'Transform'
						}
					],
					nm: 'Shape 1',
					np: 3,
					cix: 2,
					bm: 0,
					ix: 1,
					mn: 'ADBE Vector Group',
					hd: false
				}
			],
			ip: 0,
			op: 24,
			st: -20.6,
			bm: 0
		},
		{
			ddd: 0,
			ind: 11,
			ty: 4,
			nm: 'Shape Layer 14',
			sr: 1,
			ks: {
				o: { a: 0, k: 10, ix: 11 },
				r: { a: 0, k: 0, ix: 10 },
				p: { a: 0, k: [58.875, 56.25, 0], ix: 2 },
				a: { a: 0, k: [0, 0, 0], ix: 1 },
				s: { a: 0, k: [16, 16, 100], ix: 6 }
			},
			ao: 0,
			shapes: [
				{
					ty: 'gr',
					it: [
						{
							ind: 0,
							ty: 'sh',
							ix: 1,
							ks: {
								a: 0,
								k: {
									i: [
										[0, 0],
										[0, 0],
										[0, 1.744],
										[0, 0],
										[-1.744, 0],
										[0, 0],
										[0, -1.746],
										[0, 0],
										[-1.75, 0],
										[0, 0],
										[0, 1.784],
										[0, 0],
										[1.369, 1.369],
										[0, 0],
										[1.936, 0],
										[0, 0],
										[0, -1.784],
										[0, 0],
										[1.752, 0],
										[0, 0],
										[0, -1.744],
										[0, 0],
										[-1.746, 0]
									],
									o: [
										[0, 0],
										[1.744, 0],
										[0, 0],
										[0, -1.744],
										[0, 0],
										[1.746, 0],
										[0, 0],
										[0, 1.784],
										[0, 0],
										[1.752, 0],
										[0, 0],
										[0, -1.936],
										[0, 0],
										[-1.369, -1.369],
										[0, 0],
										[-1.75, 0],
										[0, 0],
										[0, 1.782],
										[0, 0],
										[-1.744, 0],
										[0, 0],
										[0, 1.746],
										[0, 0]
									],
									v: [
										[-176.009, 179.471],
										[-73.285, 179.471],
										[-70.114, 176.3],
										[-70.114, -75.105],
										[-66.943, -78.276],
										[80.451, -78.276],
										[83.626, -75.102],
										[83.626, -25.41],
										[86.795, -22.239],
										[176.011, -22.239],
										[179.183, -25.41],
										[179.183, -83.779],
										[177.044, -88.941],
										[88.653, -177.333],
										[83.49, -179.471],
										[-83.073, -179.471],
										[-86.244, -176.3],
										[-86.244, -89.147],
										[-89.415, -85.976],
										[-176.011, -85.976],
										[-179.182, -82.805],
										[-179.182, 176.297],
										[-176.009, 179.471]
									],
									c: true
								},
								ix: 2
							},
							nm: 'Path 1',
							mn: 'ADBE Vector Shape - Group',
							hd: false
						},
						{
							ty: 'fl',
							c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
							o: { a: 0, k: 100, ix: 5 },
							r: 1,
							bm: 0,
							nm: 'Fill 1',
							mn: 'ADBE Vector Graphic - Fill',
							hd: false
						},
						{
							ty: 'tr',
							p: { a: 0, k: [0, 0], ix: 2 },
							a: { a: 0, k: [0, 0], ix: 1 },
							s: { a: 0, k: [100, 100], ix: 3 },
							r: { a: 0, k: 0, ix: 6 },
							o: { a: 0, k: 100, ix: 7 },
							sk: { a: 0, k: 0, ix: 4 },
							sa: { a: 0, k: 0, ix: 5 },
							nm: 'Transform'
						}
					],
					nm: 'Shape 1',
					np: 3,
					cix: 2,
					bm: 0,
					ix: 1,
					mn: 'ADBE Vector Group',
					hd: false
				}
			],
			ip: 0,
			op: 1820,
			st: 0,
			bm: 0
		}
	],
	markers: []
};
