/* eslint-disable no-console */
/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import {
  ANNOTATION_EDIT_URL,
  ANNOTATION_VIEW_URL,
  Entity,
  RELATION_OWNED_BY,
  RELATION_PART_OF,
} from '@backstage/catalog-model';
import {
  CodeSnippet,
  Table,
  TableColumn,
  TableProps,
  WarningPanel,
} from '@backstage/core-components';
import {
  getEntityRelations,
  humanizeEntityRef,
  useEntityList,
  useStarredEntities,
} from '@backstage/plugin-catalog-react';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Edit from '@material-ui/icons/Edit';
import OpenInNew from '@material-ui/icons/OpenInNew';
import Star from '@material-ui/icons/Star';
import StarBorder from '@material-ui/icons/StarBorder';
import { capitalize } from 'lodash';
import React, { ReactNode, useCallback, useMemo } from 'react';
import { useKubernetesDeployments } from '../../components/catalog/useKubernetesDeployments';
import { columnFactories } from './columns';
import { KubernetesDetailsPanel } from './KubernetesDetailsPanel';
import { CatalogTableRow } from './types';

/**
 * Props for {@link CatalogTable}.
 *
 * @public
 */
export interface CatalogTableProps {
  columns?: TableColumn<CatalogTableRow>[];
  actions?: TableProps<CatalogTableRow>['actions'];
  tableOptions?: TableProps<CatalogTableRow>['options'];
  emptyContent?: ReactNode;
  subtitle?: string;
}

export interface DeploymentDetails {
  tag: string;
  workflow: string;
  lastRestarted: string;
  actor: string;
}

export interface AllClusterDeploymentDetails {
  isNotDeployed: boolean;
  tst?: DeploymentDetails;
  stg?: DeploymentDetails;
  prod?: DeploymentDetails
}

const YellowStar = withStyles({
  root: {
    color: '#f3ba37',
  },
})(Star);

const refCompare = (a: Entity, b: Entity) => {
  const toRef = (entity: Entity) =>
    entity.metadata.title ||
    humanizeEntityRef(entity, {
      defaultKind: 'Component',
    });

  return toRef(a).localeCompare(toRef(b));
};

/** @public */
export const CatalogTable = (props: CatalogTableProps) => {
  const { columns, actions, tableOptions, subtitle, emptyContent } = props;
  const { isStarredEntity, toggleStarredEntity } = useStarredEntities();
  const { loading, error, entities, filters } = useEntityList();
  const { loading: releasesLoading, kubernetesObjects } = useKubernetesDeployments(60000);
  const getEntityRelease = useCallback((entity: Entity): AllClusterDeploymentDetails => {
    if (!entity.metadata.annotations || !entity.metadata.annotations['backstage.io/kubernetes-label-selector']) {
      return { isNotDeployed: true };
    }
    if (!kubernetesObjects) {
      return { isNotDeployed: true };
    }
    const labelSelector = entity.metadata.annotations['backstage.io/kubernetes-label-selector'];
    // getting list of labels because i like writing bad code
    let labelList = [labelSelector];
    if (labelSelector.includes('(')) {
      const startParens = labelSelector.indexOf('(') + 1;
      const endParens = labelSelector.length - 1;
      labelList = labelSelector.substring(startParens, endParens).split(',').map((label) => label.trim());
    }
    const tst = kubernetesObjects.items[0];
    const stg = kubernetesObjects.items[1];
    const prod = kubernetesObjects.items[2];
    const tstDeployment = tst.resources[0].resources.find((deployment: any) => {
      return deployment.metadata.labels && deployment.metadata.labels.app && labelList.includes(deployment.metadata.labels.app);
    });
    const stgDeployment = stg.resources.length > 0 ? stg.resources[0].resources.find((deployment: any) => {
      return deployment.metadata.labels && deployment.metadata.labels.app && labelList.includes(deployment.metadata.labels.app);
    }) : undefined;
    const prodDeployment = prod.resources.length > 0 ? prod.resources[0].resources.find((deployment: any) => {
      return deployment.metadata.labels && deployment.metadata.labels.app && labelList.includes(deployment.metadata.labels.app);
    }) : undefined;
    if (!tstDeployment) {
      return { isNotDeployed: true };
    }
    const details: AllClusterDeploymentDetails = {
      isNotDeployed: false,
      tst: {
        tag: tstDeployment.metadata.annotations['github.com/tag'],
        actor: tstDeployment.metadata.annotations['github.com/actor'],
        workflow: tstDeployment.metadata.annotations['github.com/action_url'],
        lastRestarted: tstDeployment.spec.template.metadata.annotations ? tstDeployment.spec.template.metadata.annotations['kubectl.kubernetes.io/restartedAt'] : '',
      },
      stg: stgDeployment ? {
        tag: stgDeployment.metadata.annotations['github.com/tag'],
        actor: stgDeployment.metadata.annotations['github.com/actor'],
        workflow: stgDeployment.metadata.annotations['github.com/action_url'],
        lastRestarted: stgDeployment.spec.template.metadata.annotations?  stgDeployment.spec.template.metadata.annotations['kubectl.kubernetes.io/restartedAt'] : '',
      } : undefined,
      prod: prodDeployment ? {
        tag: prodDeployment.metadata.annotations['github.com/tag'],
        actor: prodDeployment.metadata.annotations['github.com/actor'],
        workflow: prodDeployment.metadata.annotations['github.com/action_url'],
        lastRestarted: prodDeployment.spec.template.metadata.annotations ? prodDeployment.spec.template.metadata.annotations['kubectl.kubernetes.io/restartedAt'] : '',
      } : undefined,
    };
    return details;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entities, kubernetesObjects]);

  const defaultColumns: TableColumn<CatalogTableRow>[] = useMemo(() => {
    return [
      columnFactories.createTitleColumn({ hidden: true }),
      columnFactories.createNameColumn({ defaultKind: filters.kind?.value }),
      columnFactories.createStagedReleaseTagColumn(),
      columnFactories.createReleaseTagColumn(),
      ...createEntitySpecificColumns(),
    ];

    function createEntitySpecificColumns(): TableColumn<CatalogTableRow>[] {
      switch (filters.kind?.value) {
        case 'user':
          return [];
        case 'domain':
        case 'system':
          return [columnFactories.createOwnerColumn()];
        case 'group':
        case 'template':
          return [columnFactories.createSpecTypeColumn()];
        case 'location':
          return [
            columnFactories.createSpecTypeColumn(),
            columnFactories.createSpecTargetsColumn(),
          ];
        default:
          return [
            // columnFactories.createOwnerColumn(),
            // columnFactories.createSpecTypeColumn(),
            // columnFactories.createSpecLifecycleColumn(),
          ];
      }
    }
  }, [filters.kind?.value]);

  const showTypeColumn = filters.type === undefined;
  const titlePreamble = capitalize(filters.user?.value ?? 'all');

  if (error) {
    return (
      <div>
        <WarningPanel
          severity="error"
          title="Could not fetch catalog entities."
        >
          <CodeSnippet language="text" text={error.toString()} />
        </WarningPanel>
      </div>
    );
  }

  const defaultActions: TableProps<CatalogTableRow>['actions'] = [
    ({ entity }) => {
      const url = entity.metadata.annotations?.[ANNOTATION_VIEW_URL];
      const title = 'View';

      return {
        icon: () => (
          <>
            <Typography variant="srOnly">{title}</Typography>
            <OpenInNew fontSize="small" />
          </>
        ),
        tooltip: title,
        disabled: !url,
        onClick: () => {
          if (!url) return;
          window.open(url, '_blank');
        },
      };
    },
    ({ entity }) => {
      const url = entity.metadata.annotations?.[ANNOTATION_EDIT_URL];
      const title = 'Edit';

      return {
        icon: () => (
          <>
            <Typography variant="srOnly">{title}</Typography>
            <Edit fontSize="small" />
          </>
        ),
        tooltip: title,
        disabled: !url,
        onClick: () => {
          if (!url) return;
          window.open(url, '_blank');
        },
      };
    },
    ({ entity }) => {
      const isStarred = isStarredEntity(entity);
      const title = isStarred ? 'Remove from favorites' : 'Add to favorites';

      return {
        cellStyle: { paddingLeft: '1em' },
        icon: () => (
          <>
            <Typography variant="srOnly">{title}</Typography>
            {isStarred ? <YellowStar /> : <StarBorder />}
          </>
        ),
        tooltip: title,
        onClick: () => toggleStarredEntity(entity),
      };
    },
  ];

  const rows = entities.sort(refCompare).map(entity => {
    const partOfSystemRelations = getEntityRelations(entity, RELATION_PART_OF, {
      kind: 'system',
    });
    const ownedByRelations = getEntityRelations(entity, RELATION_OWNED_BY);
    const releaseInformation = !releasesLoading && kubernetesObjects ? getEntityRelease(entity) : '';
    return {
      entity,
      resolved: {
        name: humanizeEntityRef(entity, {
          defaultKind: 'Component',
        }),
        ownedByRelationsTitle: ownedByRelations
          .map(r => humanizeEntityRef(r, { defaultKind: 'group' }))
          .join(', '),
        ownedByRelations,
        partOfSystemRelationTitle: partOfSystemRelations
          .map(r =>
            humanizeEntityRef(r, {
              defaultKind: 'system',
            }),
          )
          .join(', '),
        partOfSystemRelations,
      },
      deploymentDetails: releaseInformation
    };
  });

  const typeColumn = (columns || defaultColumns).find(c => c.title === 'Type');
  if (typeColumn) {
    typeColumn.hidden = !showTypeColumn;
  }
  const showPagination = rows.length > 20;

  return (
    <Table<CatalogTableRow>
      isLoading={loading}
      columns={columns || defaultColumns}
      detailPanel={({ rowData }) => {
        return <KubernetesDetailsPanel entity={rowData.entity} />;
      }}
      options={{
        paging: showPagination,
        pageSize: 100,
        actionsColumnIndex: -1,
        loadingType: 'linear',
        showEmptyDataSourceMessage: !loading,
        padding: 'dense',
        pageSizeOptions: [20, 50, 100],
        rowStyle: (rowData) => {
          const { deploymentDetails } = rowData;
          const { stg, prod } = deploymentDetails;
          if (stg && prod && stg.tag !== prod.tag) {
            return {
              backgroundColor: 'rgba(254, 255, 173, 0.86)',
            };
          }
          return {};
        },
        ...tableOptions,
      }}
      title={`${titlePreamble} (${entities.length})`}
      data={rows}
      actions={actions || defaultActions}
      subtitle={subtitle}
      emptyContent={emptyContent}
    />
  );
};

CatalogTable.columns = columnFactories;
